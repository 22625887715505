<script context="module" lang="ts">
    import type { Writable } from 'svelte/store';
    export interface TabCtxType {
        activeClasses: string;
        inactiveClasses: string;
        selectedValue: Writable<any>;
    }
</script>

<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    import { setContext } from 'svelte';
    import { writable } from 'svelte/store';

    export let value: any;
    export let style: 'full' | 'pill' | 'underline' | 'none' = 'full';
    export let divider = true;
    export let defaultClass = '';
    export let activeClasses = 'p-4 text-gray-600 bg-gray-100 rounded-t-lg dark:bg-gray-800 dark:text-gray-500';
    export let inactiveClasses = 'p-4 text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300';

    const defaultStyledClasses = {
        full: 'flex flex-wrap rounded border border-gray-200 divide-x divide-gray-200 dark:divide-gray-700',
        pill: 'flex flex-wrap space-x-3 rtl:space-x-reverse',
        underline: 'flex flex-wrap space-x-3 rtl:space-x-reverse',
        none: 'flex flex-wrap space-x-3 rtl:space-x-reverse',
    };

    // styles
    const styledActiveClasses = {
        full: 'py-[0.84375rem] px-4 w-full group-first:rounded-l group-last:rounded-r text-white text-sm font-bold bg-gray-600 focus:outline-none dark:bg-gray-700 dark:text-white',
        pill: 'py-3 px-4 text-white bg-gray-600 rounded-lg',
        underline: 'text-sm text-gray-900 font-semibold px-2 py-[1.25rem] text-gray-600 border-b-2 border-gray-900 dark:text-gray-500 dark:border-gray-500',
        none: '',
    };

    const styledInactiveClasses = {
        full: 'py-[0.84375rem] px-4 w-full group-first:rounded-l group-last:rounded-r text-gray-500 text-sm dar k:text-gray-400 bg-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700',
        pill: 'py-3 px-4 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white',
        underline:
            'text-sm text-gray-500 font-medium px-2 py-[1.25rem] border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-gray-500 dark:text-gray-400',
        none: '',
    };

    const selectedValue = writable(value);
    const ctx: TabCtxType = {
        activeClasses: styledActiveClasses[style] || activeClasses,
        inactiveClasses: styledInactiveClasses[style] || inactiveClasses,
        selectedValue,
    };
    $: value, syncSelectedValue();
    $: $selectedValue, syncValue();
    $: divider = ['full', 'pill'].includes(style) ? false : divider;

    setContext('ctx', ctx);

    $: ulClass = twMerge(defaultStyledClasses[style], style === 'underline' && '-mb-px', $$slots.after ? '' : 'w-full', defaultClass);

    function syncValue() {
        if (value !== $selectedValue) value = $selectedValue;
    }
    function syncSelectedValue() {
        if (value !== $selectedValue) $selectedValue = value;
    }
</script>

<div class={twMerge('w-full', $$props.class)}>
    <div class="flex items-center {$$slots.after ? 'w-full justify-between' : 'justify-start'}">
        <ul class={ulClass}>
            <slot />
        </ul>
        <slot name="after" />
    </div>
    {#if divider}
        <slot name="divider">
            <div class="h-px bg-gray-200 dark:bg-gray-700" />
        </slot>
    {/if}
</div>

<!--
  @component
    ## Features
    [Go to Tab](https://flowbite-svelte.com/docs/components/tab)
    - Setup
    - Default tabs
    - Tabs with underline
    - Tabs with icons
    - Pills tabs
    - Full width tabs
    - Components in tab contents
    - Custom style
  ## Props
    @prop value: any;
    @prop style: 'full' | 'pill' | 'underline' | 'none' = 'none';
    @prop defaultClass: string = 'flex flex-wrap space-x-2';
    @prop divider: boolean = true;
    @prop activeClasses: string =
        'p-4 text-primary-600 bg-gray-100 rounded-t-lg dark:bg-gray-800 dark:text-primary-500';
    @prop inactiveClasses: string =
        'p-4 text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300';  
    ## Example
    ```
    <script>
        import { Tabs, TabItem } from '$component/basic';
        let selectedValue = '1';
    </script>

    <Tabs bind:value={selectedValue}>
        <TabItem value="1">
            <div>탭명탭명</div>
        </TabItem>
        <TabItem value="2">
            <div>탭명탭명</div>
        </TabItem>
        <TabItem value="3">
            <div>탭명탭명</div>
        </TabItem>
        <TabItem value="4">
            <div>탭명탭명</div>
        </TabItem>
    </Tabs>
    ```
  -->
