<script lang="ts">
    import { getContext } from 'svelte';
    import type { TabCtxType } from './Tabs.svelte';
    import { twMerge } from 'tailwind-merge';

    let open = false;
    export let value: any;
    export let title = 'Tab title';
    export let activeClasses: string | undefined = undefined;
    export let inactiveClasses: string | undefined = undefined;
    export let paddingClasses: string | undefined = undefined;
    export let defaultClass = 'inline-block text-sm font-regular text-center disabled:cursor-not-allowed';

    const ctx = getContext<TabCtxType>('ctx') ?? {};
    ctx.selectedValue.subscribe(x => {
        if (x !== value) {
            open = false;
        } else {
            open = true;
        }
    });
    const onClick = () => {
        ctx.selectedValue.set(value);
    };

    let buttonClass: string;
    $: buttonClass = twMerge(
        defaultClass,
        open ? activeClasses ?? ctx.activeClasses : inactiveClasses ?? ctx.inactiveClasses,
        open && 'active',
        paddingClasses,
        // $$restProps.disabled && 'cursor-not-allowed pointer-events-none'
    );
</script>

<li class={twMerge('group', $$props.class)} role="presentation">
    <button
        type="button"
        on:click={onClick}
        on:blur
        on:click
        on:contextmenu
        on:focus
        on:keydown
        on:keypress
        on:keyup
        on:mouseenter
        on:mouseleave
        on:mouseover
        role="tab"
        {...$$restProps}
        class={buttonClass}
    >
        <slot {open}>{title}</slot>
    </button>
</li>

<!--
  @component
    ## Features
    [Go to Tab](https://flowbite-svelte.com/docs/components/tab)
  ## Props
    @prop value: string = '';
    @prop title: string = 'Tab title';
    @prop activeClasses: string | undefined = undefined;
    @prop inactiveClasses: string | undefined = undefined;
    @prop defaultClass: string =
        'inline-block text-sm font-medium text-center disabled:cursor-not-allowed';
    ## Event
    - on:blur
    - on:click
    - on:contextmenu
    - on:focus
    - on:keydown
    - on:keypress
    - on:keyup
    - on:mouseenter
    - on:mouseleave
    - on:mouseover
    ## Example
    ```
    <script>
        import { Tabs, TabItem } from '$component/basic';
        let selectedValue = '1';
    </script>

    <Tabs bind:value={selectedValue}>
        <TabItem value="1">
            <div>탭명탭명</div>
        </TabItem>
        <TabItem value="2">
            <div>탭명탭명</div>
        </TabItem>
        <TabItem value="3">
            <div>탭명탭명</div>
        </TabItem>
        <TabItem value="4">
            <div>탭명탭명</div>
        </TabItem>
    </Tabs>
    ```
  -->
